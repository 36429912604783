/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Stylesheets
 */
import './style.scss';

/**
 * Component
 */
const Section = ( {
    children, id, narrow, color, halfPadding,
} ) => {
    const classes = classNames( 'section', color ? `is-${color}` : '', halfPadding ? 'half-padding' : '' );

    return (
        <section id={id || ''} className={classes}>
            <div className="section-inner">
                { narrow ? (
                    <div className="container">
                        <div className="row">
                            <div className="col-12 offset-lg-2 col-lg-8">
                                { children }
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        { children }
                    </div>
                ) }
            </div>
        </section>
    );
};

Section.propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.string,
    color: PropTypes.string,
    narrow: PropTypes.bool,
    halfPadding: PropTypes.bool,
};

Section.defaultProps = {
    color: '',
    id: '',
    narrow: false,
    halfPadding: false,
};

export default Section;

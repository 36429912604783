/*
 * External dependencies
 */
import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';
import classNames from 'classnames';

/**
 * Component
 */
const InternalLink = ( { children, to, className, onClick, style } ) => {
    const classes = classNames( 'internal-link', className );

    return (
        <AniLink
            className={ classes }
            style={ style }
            to={ to }
            onClick={ onClick }
            cover
            bg="#2c3e50"
            direction="left"
            duration="1"
        >
            { children }
        </AniLink>
    );
};

InternalLink.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    to: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

InternalLink.defaultProps = {
    className: '',
    onClick: () => null,
};

export default InternalLink;

/**
 * SEO component that queries for data with
 * Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import OGImage from '../../images/og-image.png';
import TwitterImage from '../../images/twitter-image.png';

function SEO( props ) {
    const {
        description,
        lang,
        meta,
        title,
        imageUrl,
        imageAlt,
    } = props;

    const site = {
        siteMetadata: {
            title: 'Tom Hirst',
            description: 'Freelance WordPress developer and WordPress consultant.',
            author: 'Tom Hirst',
            image: {
                src: `https://www.tomhirst.com${OGImage}`,
                alt: 'Tom Hirst',
            },
        },
    };

    const metaDescription = description || site.siteMetadata.description;
    const ogImageSrc = imageUrl || site.siteMetadata.image.src;
    const twitterImageSrc = imageUrl || `https://www.tomhirst.com${TwitterImage}`;
    const imageAltText = imageAlt || site.siteMetadata.image.alt;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    name: 'description',
                    content: metaDescription,
                },
                {
                    property: 'og:title',
                    content: title,
                },
                {
                    property: 'og:description',
                    content: metaDescription,
                },
                {
                    property: 'og:image',
                    content: ogImageSrc,
                },
                {
                    property: 'og:image:alt',
                    content: imageAltText,
                },
                {
                    property: 'og:type',
                    content: 'website',
                },
                {
                    name: 'twitter:card',
                    content: 'summary_large_image',
                },
                {
                    name: 'twitter:creator',
                    content: site.siteMetadata.author,
                },
                {
                    name: 'twitter:title',
                    content: title,
                },
                {
                    property: 'twitter:image',
                    content: twitterImageSrc,
                },
                {
                    property: 'twitter:image:alt',
                    content: imageAltText,
                },
                {
                    name: 'twitter:description',
                    content: metaDescription,
                },
                {
                    name: 'google-site-verification',
                    content: 'mCYJHESYhH1sV2qoFOxUG5PtqhS7zdChr5aY-RHAAtw',
                },
            ].concat( meta )}
        />
    );
}

SEO.defaultProps = {
    lang: 'en',
    meta: [],
    description: '',
    imageUrl: '',
    imageAlt: '',
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf( PropTypes.object ),
    title: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    imageAlt: PropTypes.string,
};

export default SEO;

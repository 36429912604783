/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Internal dependencies
 */
import InternalLink from '../internal-link';

/**
 * Stylesheets
 */
import './style.scss';

/**
 * Component
 */
const Button = ( {
    onClick, to, text, color, external, anchor, icon, dummy, small, style, gumroadSingle,
} ) => {
    const classes = classNames( 'button', color ? `is-${color}` : '', icon ? 'has-icon' : '', small ? 'is-small' : '' );

    if ( dummy ) {
        return (
            <div className={classes} style={ style }>
                { text }
                { icon && <FontAwesomeIcon icon={icon} /> }
            </div>
        );
    }

    if ( ( ! to && ! onClick ) || ! text ) {
        return null;
    }

    if ( external || anchor ) {
        if ( gumroadSingle ) {
            return (
                <a href={to} className={classes} style={ style } onClick={ onClick } data-gumroad-single-product="true">
                    { text }
                    { icon && <FontAwesomeIcon icon={icon} /> }
                </a>
            );
        }

        return (
            <a href={to} className={classes} style={ style } onClick={ onClick }>
                { text }
                { icon && <FontAwesomeIcon icon={icon} /> }
            </a>
        );
    }

    return (
        <InternalLink className={classes} style={ style } to={to}>
            { text }
            { icon && <FontAwesomeIcon icon={icon} /> }
        </InternalLink>
    );
};

Button.propTypes = {
    onClick: PropTypes.func,
    to: PropTypes.string,
    text: PropTypes.string.isRequired,
    color: PropTypes.string,
    external: PropTypes.bool,
    anchor: PropTypes.bool,
    icon: PropTypes.string,
    dummy: PropTypes.bool,
    gumroadSingle: PropTypes.bool,
};

Button.defaultProps = {
    onClick: () => null,
    color: 'hollow',
    external: false,
    anchor: false,
    icon: '',
    to: '',
    dummy: false,
    gumroadSingle: false,
};

export default Button;

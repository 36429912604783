/*
 * External dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { RemoveScroll } from 'react-remove-scroll';

/**
 * Internal dependencies
 */
import Button from '../button';
import InternalLink from '../internal-link';

/**
 * Assets
 */
import Logo from './logo.svg';

/**
 * Stylesheets
 */
import './style.scss';

/**
 * Component
 */
class Header extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            closeRight: 0,
            mobileNavOpen: false,
        };

        this.ctaNode = React.createRef();

        this.handleMobileNavToggle = this.handleMobileNavToggle.bind( this );
        this.handleCloseMobileNav = this.handleCloseMobileNav.bind( this );
        this.calculateCloseRight = this.calculateCloseRight.bind( this );
        this.closeMobileNavOnDesktop = this.closeMobileNavOnDesktop.bind( this );
    }

    componentDidMount() {
        this.calculateCloseRight();
        window.addEventListener( 'resize', this.calculateCloseRight );
        window.addEventListener( 'resize', this.closeMobileNavOnDesktop );
    }

    componentWillUnmount() {
        window.removeEventListener( 'resize', this.calculateCloseRight );
        window.addEventListener( 'resize', this.closeMobileNavOnDesktop );
    }

    calculateCloseRight() {
        const {
            noCta,
        } = this.props;

        const largeCloseMargin = window.outerWidth < 767 ? 20 : 40;
        const smallCloseMargin = window.outerWidth < 767 ? 10 : 20;
        const closeMargin = noCta ? smallCloseMargin : largeCloseMargin;
        const ctaWidth = ( this.ctaNode && this.ctaNode.current && this.ctaNode.current.clientWidth ) || 0;
        const closeRight = `${ctaWidth + closeMargin}px`;

        this.setState( {
            closeRight,
        } );
    }

    closeMobileNavOnDesktop() {
        if ( window.outerWidth > 1023 ) {
            this.handleCloseMobileNav();
        }
    }

    handleMobileNavToggle() {
        const {
            mobileNavOpen,
        } = this.state;

        this.setState( {
            mobileNavOpen: ! mobileNavOpen,
        } );
    }

    handleCloseMobileNav() {
        this.setState( {
            mobileNavOpen: false,
        } );
    }

    renderNavItems( mobile ) {
        const {
            ctaText,
            ctaAnchor,
            noCta,
        } = this.props;

        return (
            <ul>
                <li>
                    <InternalLink to="/about/" onClick={ this.handleCloseMobileNav }>
                        About
                    </InternalLink>
                </li>
                <li>
                    <InternalLink to="/products/" onClick={ this.handleCloseMobileNav }>
                        Products
                    </InternalLink>
                </li>
                <li>
                    <InternalLink to="/wordpress-consultant/" onClick={ this.handleCloseMobileNav }>
                        Consultancy
                    </InternalLink>
                </li>
                <li>
                    <InternalLink to="/mentorship/" onClick={ this.handleCloseMobileNav }>
                        Mentorship
                    </InternalLink>
                </li>
                <li>
                    <InternalLink to="/articles/" onClick={ this.handleCloseMobileNav }>
                        Articles
                    </InternalLink>
                </li>
                { ! noCta
                && (
                    <li className="cta" ref={ this.ctaNode }>
                        <Button
                            onClick={ this.handleCloseMobileNav }
                            to={ ctaAnchor || '#hire-me' }
                            icon="arrow-right"
                            text={ ctaText || 'Hire Me' }
                            color={ mobile ? '' : 'green' }
                            anchor
                        />
                    </li>
                ) }
            </ul>
        );
    }

    render() {
        const {
            noCta,
            noNav,
        } = this.props;

        const {
            closeRight,
            mobileNavOpen,
        } = this.state;

        const mobileNavClasses = classNames( 'header-mobile-nav', {
            'is-open': mobileNavOpen,
            'no-cta': noCta,
        } );

        const mobileNavToggleClasses = classNames( 'header-mobile-nav-toggle', {
            'is-open': mobileNavOpen,
        } );

        const headerNavClasses = classNames( 'header-nav', {
            'no-cta': noCta,
        } );

        return (
            <header className="header">
                { ! noNav && (
                    <RemoveScroll enabled={ mobileNavOpen }>
                        <nav className={ mobileNavClasses }>
                            <button
                                className="header-mobile-nav-close"
                                onClick={ this.handleMobileNavToggle }
                                style={ { right: closeRight } }
                                type="button"
                            >
                                <FontAwesomeIcon icon="times" />
                                Close
                            </button>
                            { this.renderNavItems( true ) }
                        </nav>
                    </RemoveScroll>
                ) }
                <div className="header-inner container">
                    <InternalLink className="header-logo" to="/">
                        <Logo />
                    </InternalLink>
                    { ! noNav && (
                        <div className="header-nav-group">
                            <button
                                className={ mobileNavToggleClasses }
                                onClick={ this.handleMobileNavToggle }
                                type="button"
                            >
                                <FontAwesomeIcon icon="bars" />
                                Menu
                            </button>
                            <nav className={ headerNavClasses }>
                                { this.renderNavItems() }
                            </nav>
                        </div>
                    ) }
                </div>
            </header>
        );
    }
}

Header.propTypes = {
    ctaText: PropTypes.string,
    ctaAnchor: PropTypes.string,
    noCta: PropTypes.bool,
    noNav: PropTypes.bool,
};

Header.defaultProps = {
    ctaText: '',
    ctaAnchor: '',
    noCta: false,
    noNav: false,
};

export default Header;

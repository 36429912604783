/**
 * External dependencies
 */
import axios from 'axios';

// Check if we're in browser.
export const isBrowser = typeof window !== 'undefined';

// Get the current user from local storage.
const getUser = () => ( window.localStorage.gatsbyUser
    ? JSON.parse( window.localStorage.gatsbyUser ) : {} );

// Set user in local storage.
const setUser = ( user ) => {
    window.localStorage.gatsbyUser = JSON.stringify( user );
};

// Handle login.
export const handleLogin = ( email, password ) => {
    if ( ! isBrowser ) return false;

   return axios.post( `${process.env.GATSBY_BACK_END_URL}/login`, {
        email,
        password,
    } ).then( ( response ) => {
        const { data } = response;

        const {
            email: emailAddress,
            name,
            stripeCustomerId,
            status,
            message,
        } = data;

        if ( status === 'success' ) {

            setUser( {
                emailAddress,
                name,
                stripeCustomerId,
            } );

            window.location.replace( '/account' );

            return {
                status,
                message,
            };
        }

        return {
            status,
            message,
        };
    } ).catch( ( error ) => {
        return {
            status: 'error',
            message: error,
        };
   } );
};

// Handle change password.
export const handleChangePassword = ( email, currentPassword, newPassword ) => {
    if ( ! isBrowser ) return false;

    return axios.post( `${process.env.GATSBY_BACK_END_URL}/change-password`, {
        email,
        currentPassword,
        newPassword,
    } ).then( ( response ) => {
        const { data } = response;

        const {
            status,
            message,
        } = data;

        return {
            status,
            message,
        };
    } ).catch( ( error ) => {
        return {
            status: 'error',
            message: error,
        };
    } );
};

// Handle reset password.
export const handleResetPassword = ( email ) => {
    if ( ! isBrowser ) return false;

    return axios.post( `${process.env.GATSBY_BACK_END_URL}/password-reset`, {
        email,
    } ).then( ( response ) => {
        const { data } = response;

        const {
            status,
            message,
        } = data;

        return {
            status,
            message,
        };
    } ).catch( ( error ) => {
        return {
            status: 'error',
            message: 'There was a problem resetting your password at this time.',
            error,
        };
    } );
};

// Handle update password.
export const handleUpdatePassword = ( token, newPassword, confirmNewPassword ) => {
    if ( ! isBrowser ) return false;

    return axios.post( `${process.env.GATSBY_BACK_END_URL}/update-password`, {
        token,
        newPassword,
        confirmNewPassword,
    } ).then( ( response ) => {
        const { data } = response;

        const {
            status,
            message,
        } = data;

        return {
            status,
            message,
        };
    } ).catch( ( error ) => {
        return {
            status: 'error',
            message: 'There was a problem changing your password at this time.',
            error,
        };
    } );
};

// Check for a valid user in local storage.
export const isLoggedIn = () => {
    if ( !isBrowser ) return false;

    const user = getUser();

    return !!user.emailAddress;
};

// Get the current user in local storage.
export const getCurrentUser = () => isBrowser && getUser();

// Log out the current user by clearing local storage.
export const logout = ( callback ) => {
    if ( ! isBrowser ) return;

    setUser( {} );
    callback();
};

export const handleLogout = () => {
    logout( () => {
        window.location.replace( '/login' );
    } );
};

/*
 * External dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import classNames from 'classnames';
import parse from 'html-react-parser';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


/**
 * Internal dependencies
 */
import { serializeFormData } from '../../helpers';

/**
 * Stylesheets
 */
import './style.scss';

/**
 * Component
 */
class Form extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            'email-address-mailing-list': '',
            result: '',
            msg: '',
        };

        this.emailAddressInputMailingList = React.createRef();
        this.contactForm = React.createRef();
    }

    handleFormFieldChange = ( event ) => {
        this.setState( { [event.target.name]: event.target.value } );
    };

    handleMailingListFormSubmit = async ( event ) => {
        event.preventDefault();

        const {
            pathname,
            list,
        } = this.props;

        const listFields = {
            PATHNAME: pathname,
            NEWSLETTER: 'true',
        };

        const {
            'email-address-mailing-list': email,
        } = this.state;

        if ( email ) {
            let result;

            if ( list ) {
                result = await addToMailchimp( email, listFields, list );
            } else {
                result = await addToMailchimp( email, listFields );
            }
            this.setState( { result: result && result.result, msg: result && result.msg } );
        } else {
            this.setState( { result: 'error', msg: 'Please enter your email address.' } );
            this.emailAddressInputMailingList.current.focus();
        }
    };

    renderMailingListForm = () => {
        const {
            buttonAsideText,
            buttonText,
            heading,
            leadText,
        } = this.props;

        const {
            msg,
            result,
        } = this.state;

        const formButtonAsideTextClasses = classNames( 'form-button-aside-text', result ? `is-${result}` : '' );

        return (
            <div className="form" id="mailing-list-form">
                { heading && (
                    <h3>{ heading || "Don't miss out!" }</h3>
                ) }
                { leadText && (
                    <p>{ leadText || 'I occasionally send out free WordPress advice to my mailing list. Join the other business owners benefiting from this information by signing up now!' }</p>
                ) }
                { ( heading || leadText ) && <hr /> }
                <form onSubmit={this.handleMailingListFormSubmit}>
                    <fieldset>
                        <div className="input-wrap">
                            <label htmlFor="email-address-mailing-list">
                                Email address
                                <input
                                    ref={this.emailAddressInputMailingList}
                                    name="email-address-mailing-list"
                                    id="email-address-mailing-list"
                                    type="email"
                                    onChange={this.handleFormFieldChange}
                                    placeholder="j.smith@gmail.com"
                                    required
                                />
                            </label>
                        </div>
                        <div className="input-wrap d-md-flex align-items-md-center">
                            <button className="button is-green has-icon flex-md-shrink-0" type="submit">
                                { buttonText || 'Sign up now' }
                                <FontAwesomeIcon icon="arrow-right" />
                            </button>
                            <span className={formButtonAsideTextClasses}>
                                { result === 'success' && <FontAwesomeIcon icon="check" /> }
                                { ( msg && parse( msg ) ) || buttonAsideText || '' }
                            </span>
                        </div>
                    </fieldset>
                </form>
            </div>
        );
    };

    handleContactFormSubmit = ( event ) => {
        event.preventDefault();

        const postUrl = '/';

        const {
            pathname,
        } = this.props;

        const {
            'name-contact': name,
            'email-address-contact': email,
            'message-contact': message,
            'honeypot-contact': honeypot,
        } = this.state;

        const formData = {
            'name-contact': name,
            'email-address-contact': email,
            'message-contact': message,
            'form-name': 'contact-form',
            subject: 'Hire form enquiry from tomhirst.com',
            'pathname-contact': pathname,
            'honeypot-contact': typeof honeypot === 'undefined' ? '' : honeypot,
        };

        const axiosConfig = {
            header: { 'Content-Type': 'application/x-www-form-urlencoded' },
        };

        if ( ! name || ! email || ! message ) {
            this.setState( { result: 'error', msg: 'Please fill out all the form fields.' } );
        } else {
            axios.post( postUrl, serializeFormData( formData ), axiosConfig )
                .then( () => {
                    this.setState( { result: 'success', msg: 'Thanks for your message! I&apos;ll be in touch soon.' } );
                } )
                .catch( () => {
                    this.setState( {
                        result: 'error',
                        msg: 'There was an error submitting the form. Please try again later.',
                    } );
                } );
        }
    };

    renderContactForm = () => {
        const {
            buttonText,
            heading,
            leadText,
            id,
            buttonAsideText,
            messagePlaceholder,
            pathname,
            messageLabel,
            budgetLabel,
        } = this.props;

        const {
            msg,
            result,
        } = this.state;

        const formButtonAsideTextClasses = classNames( 'form-button-aside-text', result ? `is-${result}` : '' );

        return (
            <div id={id || '#contact-form'} className="form">
                { heading && (
                    <h3>{ heading }</h3>
                ) }
                { leadText && (
                    <p>{ leadText }</p>
                ) }
                { ( heading || leadText ) && <hr /> }
                <form
                    ref={this.contactForm}
                    data-netlify="true"
                    netlify-honeypot="honeypot-contact"
                    name="contact-form"
                    onSubmit={this.handleContactFormSubmit}
                >
                    <fieldset>
                        <div className="input-wrap is-hidden">
                            <label htmlFor="honeypot-contact">
                                <input name="honeypot-contact" id="honeypot-contact" />
                            </label>
                            <label htmlFor="pathname-contact">
                                Page
                                <input name="pathname-contact" id="pathname-contact" type="hidden" value={pathname} />
                            </label>
                            <label htmlFor="form-name">
                                <input name="form-name" id="form-name" type="hidden" value="contact-form" />
                            </label>
                            <label htmlFor="subject">
                                Subject
                                <input
                                    name="subject"
                                    id="subject"
                                    type="hidden"
                                    value="Hire form enquiry from tomhirst.com"
                                />
                            </label>
                        </div>
                        <div className="input-wrap">
                            <label htmlFor="name-contact">
                                Name
                                <input
                                    name="name-contact"
                                    id="name-contact"
                                    type="text"
                                    placeholder="J Smith"
                                    onChange={this.handleFormFieldChange}
                                    required
                                />
                            </label>
                        </div>
                        <div className="input-wrap">
                            <label htmlFor="email-address-contact">
                                Email address
                                <input
                                    name="email-address-contact"
                                    id="email-address-contact"
                                    type="email"
                                    placeholder="j.smith@gmail.com"
                                    onChange={this.handleFormFieldChange}
                                    required
                                />
                            </label>
                        </div>
                        <div className="input-wrap">
                            <label htmlFor="message-contact">
                                { messageLabel || "Tell me about your project" }
                                <textarea
                                    name="message-contact"
                                    id="message-contact"
                                    placeholder={messagePlaceholder || "Hi Tom, I've got an exciting project to talk to you about."}
                                    onChange={this.handleFormFieldChange}
                                    required
                                />
                            </label>
                            <span style={{ fontSize: 14, color: '#666', fontWeight: 'bold' }}>{ budgetLabel || "Projects from £5000" }</span>
                        </div>
                        <div className="input-wrap d-md-flex align-items-md-center">
                            <button className="button is-green has-icon flex-md-shrink-0" type="submit">
                                { buttonText || 'Hire me today' }
                                <FontAwesomeIcon icon="arrow-right" />
                            </button>
                            <span className={formButtonAsideTextClasses}>
                                { result === 'success' && <FontAwesomeIcon icon="check" /> }
                                { ( msg && parse( msg ) ) || buttonAsideText || '' }
                            </span>
                        </div>
                    </fieldset>
                </form>
            </div>
        );
    };

    render() {
        const {
            mailingList,
            contact,
        } = this.props;

        if ( mailingList ) {
            return this.renderMailingListForm();
        }
        if ( contact ) {
            return this.renderContactForm();
        }
        return null;
    }
}

Form.propTypes = {
    buttonText: PropTypes.string,
    heading: PropTypes.string,
    leadText: PropTypes.string,
    mailingList: PropTypes.bool,
    contact: PropTypes.bool,
    id: PropTypes.string,
    buttonAsideText: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    messagePlaceholder: PropTypes.string,
    messageLabel: PropTypes.string,
    budgetLabel: PropTypes.string,
    list: PropTypes.string,
};

Form.defaultProps = {
    buttonText: '',
    heading: '',
    leadText: '',
    mailingList: false,
    contact: false,
    id: '',
    buttonAsideText: '',
    messagePlaceholder: '',
    list: '',
    messageLabel: '',
    budgetLabel: '',
};

export default Form;

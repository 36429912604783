// Helper functions
export const serializeFormData = ( formData ) => {
    if ( ! formData ) {
        return null;
    }

    return Object.keys( formData ).map( key => `${key}=${formData[key]}` ).join( '&' );
};

export default serializeFormData;

/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { library, config } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck,
    faArrowDown,
    faArrowRight,
    faArrowUp,
    faEnvelope,
    faUser,
    faQuoteLeft,
    faParachuteBox,
    faNewspaper,
    faBars,
    faTimes,
    faThumbsDown,
    faTimesCircle,
    faCheckCircle,
    faPencilRuler,
    faUserEdit,
    faPlayCircle,
    faCommentDollar,
    faHandshake,
    faTags,
    faTrophy,
    faSmileBeam,
    faHandHoldingUsd,
    faFileInvoiceDollar,
    faBalanceScale,
    faHeart,
    faCalendarCheck,
    faClock,
    faNetworkWired,
    faUsers,
    faDownload,
    faArrowLeft,
    faBook,
    faExternalLinkAlt,
    faReplyAll,
} from '@fortawesome/free-solid-svg-icons';

/**
 * Internal dependencies
 */
import Header from '../header';
import Footer from '../footer';
import InternalLink from '../internal-link';

/**
 * Stylesheets
 */
import '@fortawesome/fontawesome-svg-core/styles.css';
import './style.scss';

/**
 * Init FontAwesome library
 */
config.autoAddCss = false;
library.add( fab, faCheck, faArrowDown, faArrowRight, faArrowLeft, faArrowUp,
    faEnvelope, faUser, faQuoteLeft, faParachuteBox, faNewspaper,
    faBars, faTimes, faThumbsDown, faTimesCircle, faCheckCircle, faPencilRuler, faUserEdit, faPlayCircle,
    faCommentDollar, faHandshake, faTags, faTrophy, faSmileBeam, faHandHoldingUsd, faFileInvoiceDollar,
    faBalanceScale, faHeart, faCalendarCheck, faClock, faNetworkWired, faUsers, faDownload, faBook, faExternalLinkAlt,
    faReplyAll,
);

/**
 * Init smooth scroll
 */
if ( typeof window !== 'undefined' ) {
    // eslint-disable-next-line global-require
    require( 'smooth-scroll' )( 'a[href*="#"]', { speed: 300, speedAsDuration: true } );
}

const Layout = ( {
    children, hideHeader, location, ctaText, ctaAnchor, noCta, topBanner, noSignUpForm, hideLinks, noNav,
} ) => (
    <div className="layout">
        { topBanner && (
            <InternalLink className="top-banner" to="/wordpress-site-audit/">
                <FontAwesomeIcon icon="parachute-box" />
                <strong>New product:</strong> WordPress Site Audit - &pound;999
            </InternalLink>
        ) }
        { ! hideHeader && (
            <Header
                location={location}
                ctaText={ctaText}
                ctaAnchor={ctaAnchor}
                noCta={noCta}
                noNav={noNav}
            />
        ) }
        <main className={ topBanner ? 'has-top-banner main-content' : 'main-content' }>
            { children }
        </main>
        <Footer location={location} noSignUpForm={noSignUpForm} hideLinks={hideLinks} />
    </div>
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    hideHeader: PropTypes.bool,
    location: PropTypes.objectOf( PropTypes.any ),
    ctaText: PropTypes.string,
    ctaAnchor: PropTypes.string,
    noCta: PropTypes.bool,
    noSignUpForm: PropTypes.bool,
    noNav: PropTypes.bool,
};

Layout.defaultProps = {
    hideHeader: false,
    location: {},
    ctaText: '',
    ctaAnchor: '',
    noCta: false,
    noSignUpForm: false,
    noNav: false,
};

export default Layout;

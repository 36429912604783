/*
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Internal dependencies
 */
import Form from '../form';
import InternalLink from '../internal-link';
import Section from '../section';
import { isLoggedIn } from '../../lib/auth';

/**
 * Assets
 */
import Logo from './logo.svg';

/**
 * Stylesheets
 */
import './style.scss';

/**
 * Component
 */
const Footer = ( props ) => {
    const {
        location,
        noSignUpForm,
        hideLinks,
    } = props;

    return (
        <div>
            { ! noSignUpForm && (
                <Section
                    narrow
                    color="green"
                >
                    <h2>Join my mailing list</h2>
                    <p>
                        The people on my mailing list are the first to hear about my new content.
                        Join them by signing up now!
                    </p>
                    <Form
                        mailingList
                        buttonAsideText="No spam, ever."
                        pathname={location && location.pathname}
                    />
                </Section>
            ) }
            <footer className="footer">
                { ! hideLinks && (
                    <div className="footer-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-4">
                                    <nav className="footer-nav">
                                        <ul>
                                            <li>
                                                <a href="mailto:hello@tomhirst.com?subject=Email enquiry from tomhirst.com">
                                                    <FontAwesomeIcon icon="envelope" />
                                                    Email me
                                                </a>
                                            </li>
                                            <li>
                                                <InternalLink to="/about/">
                                                    <FontAwesomeIcon icon="user" />
                                                    About me
                                                </InternalLink>
                                            </li>
                                            <li>
                                                <InternalLink to="/articles/">
                                                    <FontAwesomeIcon icon="newspaper" />
                                                    Articles
                                                </InternalLink>
                                            </li>
                                            <li>
                                                <InternalLink to="/reads/">
                                                    <FontAwesomeIcon icon="book" />
                                                    My Reading
                                                </InternalLink>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <nav className="footer-nav">
                                        <ul>
                                            <li>
                                                <a href="https://twitter.com/tom_hirst/">
                                                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                                                    Follow on Twitter
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/tom_hirst/">
                                                    <FontAwesomeIcon icon={['fab', 'instagram']} />
                                                    Follow on Instagram
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.linkedin.com/in/tomhirst/">
                                                    <FontAwesomeIcon icon={['fab', 'linkedin']} />
                                                    Connect on LinkedIn
                                                </a>
                                            </li>
                                            <li>
                                                <InternalLink to="/wordpress-site-audit/">
                                                    <FontAwesomeIcon icon={['fab', 'wordpress-simple']} />
                                                    WordPress Site Audit
                                                </InternalLink>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="col-12 col-lg-4">
                                    <nav className="footer-nav">
                                        <ul>
                                            <li>
                                                <InternalLink to="/mentorship/">
                                                    <FontAwesomeIcon icon="pencil-ruler" />
                                                    Freelancing Mentorship
                                                </InternalLink>
                                            </li>
                                            <li>
                                                { isLoggedIn() ? (
                                                    <InternalLink to="/account/">
                                                        <FontAwesomeIcon icon="user-edit" />
                                                        Your Account
                                                    </InternalLink>
                                                ) : (
                                                    <InternalLink to="/login/">
                                                        <FontAwesomeIcon icon="user-edit" />
                                                        Mentorship Login
                                                    </InternalLink>
                                                ) }
                                            </li>
                                            <li>
                                                <InternalLink to="/wordpress-consultant/">
                                                    <FontAwesomeIcon icon={['fab', 'wordpress-simple']} />
                                                    WordPress Consultant
                                                </InternalLink>
                                            </li>
                                            <li>
                                                <InternalLink to="/freelance-next-js-developer/">
                                                    <FontAwesomeIcon icon={['fab', 'js']} />
                                                    Freelance Next.js Developer
                                                </InternalLink>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                ) }
                <div className="footer-lower">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <Logo className="footer-logo" />
                            </div>
                            <div className="col-12 col-md-8">
                                <small className="footer-copyright">
                                    Copyright &copy; 2009-{ new Date().getFullYear() } Tom Hirst Limited
                                    <br />
                                    Registered in England and Wales. Company number 08289671
                                    <br />
                                    36-40 Doncaster Road, Barnsley, South Yorkshire, S70 1TL, UK
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

Footer.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
    noSignUpForm: PropTypes.bool,
};

Footer.defaultProps = {
    location: {},
    noSignUpForm: false,
};

export default Footer;
